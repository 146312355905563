<template>
  <div>
    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteDistrict($event, null , true)"
    />
    <tm-popup
      :submit="onSubmit"
      :title="dialogTitle"
      ref="popup"
    >
      <div class="vx-row justify-center">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="اسم المنطقة"
            placeholder="اسم المنطقة"
            type="text"
            v-model="district.name"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <tm-input
            class="w-full"
            name="تكلفة التوصيل"
            type="number"
            v-model="district.delivery_price"
            validation-rules="required"
          />
        </div>
      </div>
      <div class="vx-row justify-center mt-3">
        <div class="vx-col w-full">
          <vs-checkbox
            class="inline-flex "
            style="font-size:medium;"
            v-model="district.is_active"
          >
            مفعل
          </vs-checkbox>
        </div>
      </div>
    </tm-popup>
  </div>
</template>

<script>
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
import utilities from "@/app/shared/utilities";
import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
import AgTableIsActiveIcon from "@/app/shared/shared-components/ag-grid/AgTableIsActiveIcon";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";

const districtsRepo = SharedRepositoryFactory.get('districtsRepository');
export default {
  name: "DistrictsMain",
  components: {
    AgTable,
    TmPopup,
    TmInput
  },
  data() {
    return {
      gridApi: null,
      dialogTitle: '',
      district: {
        id: '',
        name: '',
        delivery_price: '',
        is_active: false,
      },
      gridOptions: null,
      permissions: {
        add: 'store_districts',
        delete: 'destroy_districts',
        edit: 'update_districts'
      }
    };
  },
  methods: {
    deleteDistrict(ids, index, isMultiple = false) {
      if (isMultiple)
        ids = ids.join(',');
      districtsRepo.deleteDistrict(ids).then((response) => {
        if (response.code === 200) {
          this.gridApi.refreshServerSideStore({purge: true});
        }
      });
    },
    getAll(query) {
      return districtsRepo.fetchAllDistricts(query).then((response => response));
    },
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          checkboxSelection: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'سعر التوصيل',
          field: 'delivery_price',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'مفعل',
          field: 'is_active',
          cellRendererFramework: AgTableIsActiveIcon,
          cellRendererParams: {
            isActiveIcon: 'CheckCircleIcon',
            notActiveIcon: 'XCircleIcon'
          }
        },
        {
          headerName: 'الإجراءات',
          valueGetter:
            function getObject(params) {
              return params.data.id;
            },
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (valueGetter, rowIndex) {
              self.deleteDistrict(valueGetter, rowIndex);
            },
            editRecord: function (record) {
              self.editRecord(record);
            },
            actions: ['edit', 'delete'],
            permissions: self.permissions
          },
          sortable: false
        },
      ];
    },
    createRecord() {
      utilities.initObjectMembers(this.district);
      this.district.is_active = false;
      this.dialogTitle = 'إضافة منطقة';
      this.openPopup();
    },
    openPopup() {
      this.$refs.popup.open();
    },
    editRecord(district) {
      this.dialogTitle = 'تعديل معلومات المنطقة';
      Object.assign(this.district, district);
      this.openPopup();
    },
    onSubmit() {
      if (this.district.id !== null) {
        this.updateDistrict(this.district);
      } else {
        this.storeDistrict(this.district);
      }
    },
    storeDistrict(district) {
      districtsRepo.storeDistrict(this.$objectToFD(district)).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.gridApi.refreshServerSideStore({purge: true});
        }
      });
    },
    updateDistrict(district) {
      districtsRepo.updateDistrict(this.$objectToFD(district), district.id).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.gridApi.refreshServerSideStore({purge: true});
        }
      });
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
