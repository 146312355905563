<template>
  <feather-icon
    :icon="params.isActiveIcon"
    :svg-classes="['h-8 w-8 text-success']"
    class="mb-2 mt-2"
    v-if="params.value"
  />
  <feather-icon
    :icon="params.notActiveIcon"
    :svg-classes="['h-8 w-8 text-danger']"
    class="mb-2 mt-2"
    v-else
  />
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "AgTableIsActiveIcon"
});
</script>

<style scoped>

</style>
